/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preseve that abilty, always export from Quest to regenerate this file.
 *   To setup props, bindings and actions, use the Quest editor
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from 'react';
import { styled } from '@mui/material/styles';

const JointTheWaitlist1 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  width: '100%',
  height: `91.18px`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
});

const Group4 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `296px`,
  height: `91.18px`,
  left: `0px`,
  top: `0px`,
});

const Rectangle13 = styled('div')({
  backgroundColor: `rgba(0, 117, 255, 1)`,
  borderRadius: `20.99470329284668px`,
  width: `296px`,
  height: `91.18px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const JoinTheWaitlist = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(255, 255, 255, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `700`,
  fontSize: `26.428569793701172px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  position: `absolute`,
  left: `44px`,
  top: `28px`,
});

function JointTheWaitlist(props) {
  return (
    <JointTheWaitlist1 className={props.className}>
      <Group4>
        <Rectangle13></Rectangle13>
        <JoinTheWaitlist>{`Join the waitlist`}</JoinTheWaitlist>
      </Group4>
    </JointTheWaitlist1>
  );
}

export default JointTheWaitlist;
