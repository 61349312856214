import TopNavigation from "../containers/TopNavigation";
import LandingSection from "../containers/LandingSection";
import OurMission from "../components/_bin/OurMission/OurMission";
import styled from "@emotion/styled";
import BackgroundEffectImage from "../assets/app/background_effect.png";
import ReactGA from "react-ga4";
import {routes} from "../constants";
import {useEffect} from "react";

export default function HomePage() {
    const BackgroundEffect = styled.div`
      background-image: url("${BackgroundEffectImage}");
      background-repeat: no-repeat;
      background-position: top right;
      background-size: cover;
    `
    useEffect(() => {
        ReactGA.send({hitType: "pageview", page: routes.home.path, title: routes.home.title})
    })
    return <>
        <BackgroundEffect>
            <TopNavigation/>
            <LandingSection/>
            <OurMission/>
        </BackgroundEffect>
    </>
}