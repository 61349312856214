import React from 'react';
import styled from "@emotion/styled";
import {device} from "../constants";

const VideoWrapper = styled.div`
  position: relative;
  left: 0;
  width: 95%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  border-radius: 1000px;

  @media all and (${device.tablet}) {
    width: 100%;
    height: 200px;
  }
`;

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
`;

export default function VideoPlayer({src}) {
    return (
        <VideoWrapper>
            <Video src={src} controls/>
        </VideoWrapper>
    );
}