import {AppLogo} from "./AppLogo";
import {BETA_BADGE_TEXT, BRAND_NAME, routes, SIGNUP_BUTTON_TEXT} from "../constants";
import SecondaryButton from "../components/SecondaryButton";
import styled from "@emotion/styled";
import {useNavigate} from "react-router-dom";
import ReactGA from "react-ga4";
import useAnalyticsEventTracker from "../hooks/userAnalyticsEventTracker";

export default function TopNavigation({hideSignupButton}) {
    const Container = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
    `
    const navigate = useNavigate();

    const gaEventTracker = useAnalyticsEventTracker({category: 'Sign Up'});


    function handleOnSignupClick() {
        gaEventTracker('Sign Up');
        // navigate(routes.wishlist.path);
    }

    return (
        <Container>
            <AppLogo brandName={BRAND_NAME}/>
            {!hideSignupButton && <SecondaryButton
                showBadge
                badgeLabel={BETA_BADGE_TEXT}
                onClick={handleOnSignupClick}
            >
                {SIGNUP_BUTTON_TEXT}
            </SecondaryButton>}

        </Container>
    )
}