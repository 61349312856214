import TopNavigation from "../containers/TopNavigation";
import LandingSection from "../containers/LandingSection";
import OurMission from "../components/_bin/OurMission/OurMission";
import styled from "@emotion/styled";
import BackgroundEffectImage from "../assets/app/background_effect.png";
import {Container} from "react-bootstrap";
import Spacer from "../components/Spacer";
import {BarWave} from "react-cssfx-loading";

export default function JoinWishlist() {
    const BackgroundEffect = styled.div`
        // background-image: url("${BackgroundEffectImage}");
      background-repeat: no-repeat;
      background-position: top right;
      background-size: cover;
      height: 100vh;
    `

    const GoogleFormsIFrame = styled.iframe`
      height: 2500px;
      width: 100%;
    `
    return <>
        <BackgroundEffect>
            <TopNavigation hideSignupButton/>
            <Spacer space={3}/>
            <Container>
                <GoogleFormsIFrame
                    src="https://docs.google.com/forms/d/e/1FAIpQLSfKoJ_urKV5NUBl14YEs7vYaH23kPa8PQD4RSOBss_vQmPAqA/viewform?embedded=true"
                    frameBorder="0" marginHeight="0" marginWidth="0">
                    {/*Loading…*/}
                    <BarWave color="#00ff00" width="100px" height="100px" duration="3s"/>
                </GoogleFormsIFrame>
            </Container>
        </BackgroundEffect>
    </>
}