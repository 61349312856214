import styled from "@emotion/styled";
import GlowingCubeImage from "../assets/app/glowing_cube_image.png"
import VideoPlayer from "../components/VideoPlayer";
import {JOIN_THE_WAITLIST_BUTTON_TEXT, LANDING_DESCRIPTION, LANDING_TITLE, LANDING_VIDEO_URL} from "../constants";
import {Button, Col, Container, Row} from "react-bootstrap";
import Spacer from "../components/Spacer";
import JointTheWaitlist from "../components/_bin/JointTheWaitlist/JointTheWaitlist";
import JoinTheWishListButton from "./JoinTheWishlistButton";

export default function LandingSection() {
    const Wrapper = styled.div`
      background-image: url("${GlowingCubeImage}");
      background-repeat: no-repeat;
      background-position: top right;
      background-size: 300px, 300px;
      margin: 0;
      padding: 0;
    `

    const TextContainer = styled.div`
      height: 100%;

      padding: 20px;

      display: flex;
      flex-direction: column;
      justify-content: center;
    `

    const Header = styled.h1`
      font-family: 'Jockey One';
      font-size: 2.5rem;
    `

    const Description = styled.p`
      font-size:1.2rem;
      font-family: 'Open Sans';
      line-height: 1.9;
    `
    return (<>
        <Wrapper>
            <Spacer space={2}></Spacer>
            <Container>
                <Row style={{margin: 0, padding: 0}}>
                    <Col md={7} sm={12}>
                        <TextContainer>
                            <Header>
                                {LANDING_TITLE}
                            </Header>
                            <Spacer space={2}></Spacer>
                            <Description>
                                {LANDING_DESCRIPTION}
                            </Description>
                            <Spacer space={1}/>
                            <JoinTheWishListButton/>
                            <Spacer space={3}/>
                        </TextContainer>
                    </Col>
                    <VideoPlayer src={LANDING_VIDEO_URL}/>
                </Row>
            </Container>

        </Wrapper>
    </>)
}