import styled from "@emotion/styled";
import {device} from "../constants";

export default function SecondaryButton(props) {
    const {showBadge, badgeLabel, onClick} = props;

    const Button = styled.button`
      background-color: #282828;

      &:hover {
        background-color: black;
      }


      @media all and (${device.tablet}) {
        font-size: 0.8rem;
      }
    `

    function handleOnclick() {
        if (onClick)
            onClick();
    }

    const Badge = styled.div`
      background-image: linear-gradient(to right, red, blue);
      background-image: -webkit-linear-gradient(to right, #ff0000, #0000ff);
      padding: 5px 10px;
      border-radius: 20px;
      font-size: 0.8rem;
      font-weight: bold;

      @media all and (${device.tablet}) {
        padding: 2px 6px;
        font-size: 0.6rem;
      }
    `
    return (
        <Button onClick={handleOnclick}>
            {props.children}
            {showBadge && <Badge>{badgeLabel.toUpperCase()}</Badge>}
        </Button>
    )
}