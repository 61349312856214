import 'bootstrap/dist/css/bootstrap.min.css'
import {Route, Routes} from "react-router-dom";
import HomePage from "./pages/HomePage";
import JoinWishlist from "./pages/JoinWishlist";
import {routes} from "./constants";

// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import ReactGA from "react-ga4";
import {useEffect} from "react";

const firebaseConfig = {
    apiKey: "AIzaSyCbTolM1RMWGxxjbKBOsblP3jB1FCQfu8g",
    authDomain: "space-381c4.firebaseapp.com",
    projectId: "space-381c4",
    storageBucket: "space-381c4.appspot.com",
    messagingSenderId: "105840052894",
    appId: "1:105840052894:web:d80662f8f9152378ee898f",
    measurementId: "G-91NVN2NNQ6"
};

// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// console.log(analytics);
// ReactGA.initialize('G-91NVN2NNQ6');

const TRACKING_ID = "G-91NVN2NNQ6";
ReactGA.initialize(TRACKING_ID, {
    // testMode: true
});

function App() {
    useEffect(() => {
        // ReactGA._gaCommandSendPageview(window.location.pathname + window.location.search, {});
    }, []);

    return (
        <div className="App">
            <Routes>
                <Route path={routes.home.path} element={<HomePage/>}></Route>
                <Route path={routes.wishlist.path} element={<JoinWishlist/>}></Route>
            </Routes>
        </div>
    );
}

export default App;
