import {JOIN_THE_WAITLIST_BUTTON_TEXT, routes} from "../constants";
import {Button} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

export default function JoinTheWishListButton({completion}) {
    const navigate = useNavigate();

    function handleButtonClick() {
        navigate(routes.wishlist.path);
        if (completion)
            completion();
    }

    return (
        <>
            <Button size={'lg'} onClick={handleButtonClick}
                    style={{width: 200, fontFamily: 'Jockey One'}}> {JOIN_THE_WAITLIST_BUTTON_TEXT}</Button></>
    )
}