import React from 'react';
import styled from "@emotion/styled";
import {Container} from "react-bootstrap";
import {device} from "../../../constants";

function OurMission(props) {

    const Wrapper = styled.div`
      padding: 20px;
    `
    const HeaderText = styled.h1`
      background: linear-gradient(to right, #f17979 10%, #6a6ae7 40%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 6rem;
      font-weight: bold;
      //text-align: center;

      @media all and (${device.tablet}) {
        font-size: 3rem;
      }
    `
    const SmallSpan = styled.span`
      font-size: 1rem;
      font-weight: normal;
      @media all and (${device.tablet}) {
        font-size: 0.8rem;
      }
    `

    const MediumSpan = styled.span`
      font-size: 2.5rem;
      
      @media all and (${device.tablet}) {
        font-size: 1rem;
      }
    `

    const LargeSpan = styled.span`
      font-size: 3.5rem;
      @media all and (${device.tablet}) {
        font-size: 2rem;
      }
    `
    const XLargeSpan = styled.span`
      font-size: 8rem;
      background: linear-gradient(to right, #f53030 10%, #3c3cff 40%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media all and (${device.tablet}) {
        font-size: 3rem;
      }
    `

    const Paragraph = styled.p`
      font-weight: bold;
      word-wrap: break-word;
      //max-width: 90ch;

    `
    return (
        <>
            <Wrapper>
                <Container>
                    <HeaderText>
                        Our Mission
                    </HeaderText>
                    <Paragraph>
                        <SmallSpan>is to</SmallSpan><MediumSpan> break down the barriers of technical
                        expertise</MediumSpan><SmallSpan> and </SmallSpan><MediumSpan>empower every
                        individual </MediumSpan>
                        <SmallSpan>to </SmallSpan><LargeSpan> share their story through immersive
                        experiences,</LargeSpan><SmallSpan>and <br/></SmallSpan><XLargeSpan>a new era of
                        creativity</XLargeSpan><SmallSpan> and self expression.</SmallSpan>
                    </Paragraph>
                </Container>
            </Wrapper>


        </>
    );
}

export default OurMission;
