import MainAppLogo from '../assets/app/MainLogo.png'
import styled from "@emotion/styled";

export function AppLogo({brandName}) {
    const Header = styled.a`
      color: white;
      text-decoration: none;
      font-size: 1.8rem;
      font-weight: bold;
      font-family: 'Jockey One', sans-serif;

    `
    const Container = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
    `

    const LogoContainer = styled.div`
      display: flex;
      align-items: center;
      gap: 1.2rem;
      padding: 10px;
      border-radius: 10px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.5);
      }
    `
    const AppLogoImage = styled.img`
      height: 3rem;
      width: 3rem;
      object-fit: contain;
    `
    return (
        <Container>
            <LogoContainer>
                <AppLogoImage src={MainAppLogo} alt="3Dimen App Logo"/>
                <Header href={"/"}>{brandName}</Header>
            </LogoContainer>
        </Container>
    )
}